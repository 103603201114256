import { Card, StatusLabel, STATUS_TYPE } from "@lysaab/ui-2";
import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { defineMessages, FormattedDate, useIntl } from "react-intl";
import { Amount } from "../../amount/Amount";
import { TranslatedText } from "../../TranslatedText";
import { CompoundAccountId } from "../../../data/dataAccounts";
import { PaymentStatus } from "../../../data/dataAutogiro";
import { PendingDepositTable } from "./pendingDepositTable/PendingDepositTable";
import { usePendingDeposits } from "../../../hooks/usePendingDeposits";
import { useAccounts } from "../../../hooks/useAccounts";
import AnimateHeight from "react-animate-height";
import { CardBottom } from "../../../pages/account/CardBottom";
import { TransparentButton } from "../../transparentButton/TransparentButton";
import "./PendingDepositCard.scss";
import { AnimatePresence, motion } from "framer-motion";
import { CancelPendingDeposit } from "../../../pages/overview/pendingTransactions/CancelPendingDeposit";

const messages = defineMessages({
  confirmText: {
    id: "deposits.overview.pending-table.confirm-text",
  },
  confirmButton: {
    id: "deposits.overview.pending-table.confirm-button",
  },
  cancelButton: {
    id: "deposits.overview.pending-table.cancel-button",
  },
  accountHeader: {
    id: "deposits.overview.pending-table.account-header",
  },
  dateAddedHeader: {
    id: "deposits.overview.pending-table.date-added-header",
  },
  statusHeader: {
    id: "deposits.overview.pending-table.status-header",
  },
  fromHeader: {
    id: "deposits.overview.pending-table.from-header",
  },
  amountHeader: {
    id: "deposits.overview.pending-table.amount-header",
  },
});

interface MessageWithId {
  id: string;
}

export const DepositStatusMessages = defineMessages<
  PaymentStatus,
  MessageWithId,
  Record<PaymentStatus, MessageWithId>
>({
  [PaymentStatus.WAITING_CONSENT]: {
    id: "deposits.overview.pending-table.status.awaiting-consent",
  },
  [PaymentStatus.WAITNG_DISPATCH]: {
    id: "deposits.overview.pending-table.status.awaiting-dispatch",
  },
  [PaymentStatus.SENT]: {
    id: "deposits.overview.pending-table.status.sent",
  },
});

export enum PendingDepositStatus {
  ISK_TRANSFER = "ISK_TRANSFER",
}

export interface PendingDeposit {
  status: PaymentStatus | PendingDepositStatus;
  abort?: () => Promise<boolean>;
  amount: number;
  accountId: CompoundAccountId;
  dateAdded?: string;
  transactionId?: string;
  from?: string;
}

export const PendingDepositCard: React.VFC = () => {
  const [pendingDeposits, setPendingDeposits] = useState<PendingDeposit[]>([]);
  const { accounts } = useAccounts();
  const loadPendingDeposits = usePendingDeposits();
  const [isOpen, setIsOpen] = useState(false);

  const intl = useIntl();

  const getPendingDeposits = useCallback(() => {
    if (typeof loadPendingDeposits === "undefined") {
      return;
    }
    loadPendingDeposits().then((pendingDeposits) => {
      const filteredDepositsNoIskTransfers = pendingDeposits.filter(
        (pendingDeposit) =>
          pendingDeposit.status !== PendingDepositStatus.ISK_TRANSFER
      );
      setPendingDeposits(filteredDepositsNoIskTransfers);
      // setPendingDeposits(DUMMY_DATA);
    });
  }, [loadPendingDeposits]);

  useEffect(() => {
    getPendingDeposits();
  }, [getPendingDeposits]);

  return (
    <AnimatePresence>
      {pendingDeposits.length > 0 && accounts && (
        <motion.div
          initial={false}
          animate={{ height: "auto", opacity: 1, overflow: "visible" }}
          exit={{ height: 0, opacity: 0, overflow: "hidden" }}
          transition={{ ease: "easeOut", duration: 0.5 }}
          className="pending-deposit-card"
        >
          <h4 className="title">
            <TranslatedText id="deposits.overview.pending-table.header" />
            <StatusLabel icon={false} type={STATUS_TYPE.INFO} className="badge">
              {pendingDeposits.length}
            </StatusLabel>
          </h4>
          <Card>
            <AnimateHeight height={isOpen ? "auto" : 100} animateOpacity>
              <div className="pending-deposit-table-container">
                <PendingDepositTable>
                  <thead>
                    <tr>
                      <th className="pending-col-number">
                        <TranslatedText id="deposits.overview.pending-table.amount-header" />
                      </th>
                      <th className="pending-col-text">
                        <TranslatedText id="deposits.overview.pending-table.account-header" />
                      </th>
                      <th className="pending-col-text">
                        <TranslatedText id="deposits.overview.pending-table.from-header" />
                      </th>
                      <th className="pending-col-text">
                        <TranslatedText id="deposits.overview.pending-table.status-header" />
                      </th>
                      <th className="pending-col-text">
                        <TranslatedText id="deposits.overview.pending-table.date-added-header" />
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {pendingDeposits.map((pendingDeposit) => {
                      const currentAccount = [
                        ...accounts.investmentAccounts,
                        ...accounts.savingsAccounts,
                      ].find(
                        (acc) => acc.accountId === pendingDeposit.accountId
                      );
                      return (
                        <tr
                          key={
                            pendingDeposit.accountId + pendingDeposit.dateAdded
                          }
                        >
                          <td
                            className="pending-col-number tablet-td-hr"
                            data-label={intl.formatMessage(
                              messages.amountHeader
                            )}
                          >
                            <Amount amount={pendingDeposit.amount} />
                          </td>
                          <td
                            className="pending-col-text tablet-td-hr"
                            data-label={intl.formatMessage(
                              messages.accountHeader
                            )}
                          >
                            {currentAccount?.name}
                          </td>
                          <td
                            className="pending-col-text tablet-td-hr"
                            data-label={intl.formatMessage(messages.fromHeader)}
                          >
                            {typeof pendingDeposit.from !== "undefined" ? (
                              pendingDeposit.from
                            ) : (
                              <TranslatedText id="deposits.overview.pending-table.fromAutogiro" />
                            )}
                          </td>
                          <td
                            className="pending-col-text tablet-td-hr"
                            data-label={intl.formatMessage(
                              messages.statusHeader
                            )}
                          >
                            {pendingDeposit.status &&
                              pendingDeposit.status !==
                                PendingDepositStatus.ISK_TRANSFER &&
                              intl.formatMessage(
                                DepositStatusMessages[pendingDeposit.status]
                              )}
                          </td>
                          <td
                            className="pending-col-text"
                            data-label={intl.formatMessage(
                              messages.dateAddedHeader
                            )}
                          >
                            {pendingDeposit.dateAdded && (
                              <FormattedDate
                                value={pendingDeposit.dateAdded}
                                dateStyle="medium"
                              />
                            )}
                          </td>
                          <td className="abort-container">
                            <CancelPendingDeposit
                              pendingDeposit={pendingDeposit}
                              onDepositCancelled={getPendingDeposits}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </PendingDepositTable>
              </div>
            </AnimateHeight>
            <CardBottom>
              <TransparentButton
                onClick={() => setIsOpen(!isOpen)}
                className="expand-button"
              >
                {isOpen ? (
                  <TranslatedText id="deposits.overview.pending-table.showLess" />
                ) : (
                  <TranslatedText id="deposits.overview.pending-table.showMore" />
                )}
              </TransparentButton>
            </CardBottom>
          </Card>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
