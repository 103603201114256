import { FC } from "react";
import { WithdrawalItem } from "../../../../../components/withdrawalItem/WithdrawalItem";
import {
  InvestmentAccount,
  getAccountAmount,
  isInvestmentAccount,
  isSavingsAccount,
  SavingsAccount,
} from "../../../../../data/dataAccounts";
import {
  Withdrawal,
  ExternalPendingSavingsWithdrawalResponse,
} from "../../../../../data/dataWithdrawals";
import { CancelPendingWithdrawal } from "../../../../overview/pendingTransactions/CancelPendingWithdrawal";
import type { PendingWithdrawal as PendingWithdrawalType } from "../../../../overview/pendingTransactions/PendingWithdrawals";

interface Props {
  account: InvestmentAccount | SavingsAccount;
  loadPendingAccountWithdrawals: () => void;
  pendingWithdrawal: Withdrawal | ExternalPendingSavingsWithdrawalResponse;
}

export const PendingWithdrawal: FC<Props> = ({
  pendingWithdrawal,
  account,
  loadPendingAccountWithdrawals,
}) => {
  if (!account) {
    return null;
  }

  // TODO: Make this cleaner. Maybe restructure the PendingWithdrawal type.
  let _pendingWithdrawal: PendingWithdrawalType;
  if (isSavingsAccount(account)) {
    _pendingWithdrawal = {
      pendingWithdrawal:
        pendingWithdrawal as ExternalPendingSavingsWithdrawalResponse,
      savingsAccount: account,
    };
  } else if (isInvestmentAccount(account)) {
    _pendingWithdrawal = {
      pendingWithdrawal: pendingWithdrawal as Withdrawal,
      investmentAccount: account,
    };
  } else {
    return null;
  }

  return (
    <div className="pending-withdrawal">
      <WithdrawalItem
        date={new Date(pendingWithdrawal.requested)}
        name={account.name}
        moneyOnAccount={getAccountAmount(account)}
        externalBank={pendingWithdrawal.bank}
        externalBankAccount={pendingWithdrawal.externalBankAccount}
        withdrawalAmount={
          isDrainWithdrawal(pendingWithdrawal)
            ? getAccountAmount(account).toString()
            : pendingWithdrawal.amount.toString()
        }
      />
      <CancelPendingWithdrawal
        pendingWithdrawal={_pendingWithdrawal}
        onWithdrawalCancelled={() => {
          loadPendingAccountWithdrawals();
        }}
      />
    </div>
  );
};

const isDrainWithdrawal = (
  pendingWithdrawal: Withdrawal | ExternalPendingSavingsWithdrawalResponse
): pendingWithdrawal is Withdrawal => {
  return (pendingWithdrawal as Withdrawal).drain === true;
};
