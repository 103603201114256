import * as React from "react";
import { Amount } from "../../components/amount/Amount";
import "./MonthlyInternalTransferEntry.scss";
import { MonthlyInternalTransfer } from "../../data/dataPeriodicInternalTransfer";
import { TranslatedText } from "../../components/TranslatedText";
import { CompoundAccount } from "../../data/dataAccounts";
import { AccountTypeLogo } from "../../components/accountType/AccountTypeLogo";
import { Link } from "react-router-dom";
import { getNavLink } from "../../hooks/useCountryUrls";
import { MONTHLY_INTERNAL_TRANSFER_PAGE_URL } from "../monthlyInternalTransfer/MonthlyInternalTransferStory";
import { CircleButton, Typography, Card } from "@lysaab/ui-2";
import { HideIfReadOnly } from "../../components/hideIfReadOnly/HideIfReadOnly";
import { CancelMonthlyInternalTransfer } from "./CancelMonthlyInternalTransfer";

interface Props {
  monthlyInternalTransfer: MonthlyInternalTransfer;
  accounts: CompoundAccount[];
  index?: number;
  reloadData: () => void;
}

export const MonthlyInternalTransferEntry: React.VoidFunctionComponent<
  Props
> = ({ monthlyInternalTransfer, accounts, index, reloadData }) => {
  const fromAccount = accounts.find(
    (account) => account.accountId === monthlyInternalTransfer.fromAccountId
  );
  const toAccount = accounts.find(
    (account) => account.accountId === monthlyInternalTransfer.toAccountId
  );
  if (!fromAccount || !toAccount) {
    return null;
  }
  return (
    <div className="monthly-internal-transfer-entry">
      <Card className="monthly-internal-transfer-entry__confirm-card">
        <dl>
          <div className="header-container">
            <Typography type="label">
              <TranslatedText
                id="accountPage.monthly-internal-transfers.entry.header"
                values={{ index: index }}
              />
            </Typography>

            <HideIfReadOnly>
              <div className="button-container">
                <CancelMonthlyInternalTransfer
                  monthlyTransfer={monthlyInternalTransfer}
                  onTransferCancelled={reloadData}
                  renderButton={(props) => (
                    <CircleButton
                      {...props}
                      type="button"
                      icon="Delete"
                      variant="outlined"
                    />
                  )}
                />
                <CircleButton
                  component={Link}
                  to={{
                    pathname: getNavLink(MONTHLY_INTERNAL_TRANSFER_PAGE_URL),
                    state: {
                      id: monthlyInternalTransfer.id,
                      accountId: monthlyInternalTransfer.fromAccountId,
                    },
                  }}
                  icon="Edit"
                  variant="outlined"
                />
              </div>
            </HideIfReadOnly>
          </div>
          <div className="entry-row">
            <dt>
              <Typography type="body" component="span">
                <TranslatedText id="accountPage.monthly-internal-transfers.entry.fromAccount" />
                :
              </Typography>
            </dt>
            <dd>
              <AccountTypeLogo size={24} account={fromAccount} />
              <Typography
                type="body"
                component="span"
                className="account-name__text"
              >
                {fromAccount.name}
              </Typography>
            </dd>
          </div>
          <div className="entry-row">
            <dt>
              <Typography type="body" component="span">
                <TranslatedText id="accountPage.monthly-internal-transfers.entry.toAccount" />
                :
              </Typography>
            </dt>
            <dd>
              <AccountTypeLogo size={24} account={toAccount} />
              <Typography
                type="body"
                component="span"
                className="account-name__text"
              >
                {toAccount.name}
              </Typography>
            </dd>
          </div>
          <div className="entry-row">
            <dt>
              <Typography type="body" component="span">
                <TranslatedText id="accountPage.monthly-internal-transfers.entry.amount" />
                :
              </Typography>
            </dt>
            <dd>
              <div>
                <Amount
                  minDecimals={0}
                  maxDecimals={2}
                  amount={monthlyInternalTransfer.amount}
                />{" "}
                <span className="monthly-ext">
                  /{" "}
                  <TranslatedText id="accountPage.monthly-internal-transfers.entry.period" />
                </span>
              </div>
            </dd>
          </div>
          <div className="entry-row">
            <dt>
              <Typography type="body" component="span">
                <TranslatedText id="accountPage.monthly-internal-transfers.entry.nextDeduction" />
                :
              </Typography>
            </dt>
            <dd>
              <Typography type="body" component="span">
                {monthlyInternalTransfer.nextTransferDate}
              </Typography>
            </dd>
          </div>
        </dl>
      </Card>
    </div>
  );
};
