import type { FunctionComponent } from "react";
import { useCallback, useState } from "react";
import { SNACKBAR_TYPES, useFlash } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import {
  PeriodicWithdrawal,
  dataWithdrawals,
} from "../../../../../data/dataWithdrawals";
import { CancelPendingTransaction } from "../../../../../components/pendingTransactions/CancelPendingTransaction";

interface Props {
  periodicWithdrawal: PeriodicWithdrawal;
  onPeriodicWithdrawalCancelled: (
    periodicWithdrawal: PeriodicWithdrawal
  ) => void;
}

export const CancelPeriodicWithdrawal: FunctionComponent<Props> = ({
  periodicWithdrawal,
  onPeriodicWithdrawalCancelled,
}) => {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const pushFlash = useFlash();

  const cancelPeriodicWithdrawal = useCallback(() => {
    setHasError(false);
    setIsLoading(true);

    dataWithdrawals
      .deletePeriodicWithdrawal(periodicWithdrawal.accountId)
      .then(() => {
        pushFlash({
          text: intl.formatMessage({
            id: "withdrawals.periodic.cancelSuccess",
          }),
          type: SNACKBAR_TYPES.SUCCESS,
        });
        onPeriodicWithdrawalCancelled(periodicWithdrawal);
        // We deliberately don't set isLoading to false here, as the component will flicker
        // with the "confirm"-part of <CancelPendingTransaction> component before closing.
      })
      .catch(() => {
        setHasError(true);
      });
  }, [intl, onPeriodicWithdrawalCancelled, periodicWithdrawal, pushFlash]);

  return (
    <CancelPendingTransaction
      onCancelTransaction={cancelPeriodicWithdrawal}
      isCancellable={true}
      isLoading={isLoading}
      hasError={hasError}
      cancelMessage={intl.formatMessage({ id: "withdrawals.periodic.cancel" })}
      errorMessage={intl.formatMessage({
        id: "withdrawals.periodic.cancelError",
      })}
      confirmCancelMessage={intl.formatMessage({
        id: "withdrawals.periodic.confirmCancel",
      })}
      denyCancelMessage={intl.formatMessage({
        id: "withdrawals.periodic.denyCancel",
      })}
    />
  );
};
