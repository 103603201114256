import type { FunctionComponent } from "react";
import { useCallback, useState } from "react";
import {
  PendingInternalTransfer,
  dataTransfer,
} from "../../../data/dataTransfer";
import { EventTracker } from "../../../components/eventTracker/EventTracker";
import { TrackerEvent } from "../../../data/dataCustomerTracking";
import { SNACKBAR_TYPES, useFlash } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { CancelPendingTransaction } from "../../../components/pendingTransactions/CancelPendingTransaction";
import { useCurrency } from "../../../context/LocalizationContext";

interface Props {
  pendingTransfer: PendingInternalTransfer;
  onTransferCancelled: () => void;
}

export const CancelPendingInternalTransfer: FunctionComponent<Props> = ({
  pendingTransfer,
  onTransferCancelled,
}) => {
  const intl = useIntl();
  const currency = useCurrency();
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const pushFlash = useFlash();

  const cancelTransfer = useCallback(() => {
    setHasError(false);
    setIsLoading(true);

    dataTransfer
      .deletePendingInternalTransfer(pendingTransfer.transactionId)
      .then(() => {
        const amountMsg = intl.formatNumber(pendingTransfer.amount || 0, {
          style: "currency",
          currency,
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });

        EventTracker.track({
          event: TrackerEvent.DELETE_INTERNAL_TRANSFER,
          message: `Cancelled internal transfer of ${amountMsg} from account ${pendingTransfer.fromAccountId} to account ${pendingTransfer.toAccountId}`,
        });
        pushFlash({
          text: intl.formatMessage({
            id: "pending-internal-transfer.cancelSuccess",
          }),
          type: SNACKBAR_TYPES.SUCCESS,
        });
        onTransferCancelled();
        // We deliberately don't set isLoading to false here, as the component will flicker
        // with the "confirm"-part of <CancelPendingTransaction> component before closing.
      })
      .catch(() => {
        setHasError(true);
        setIsLoading(false);
      });
  }, [
    currency,
    intl,
    onTransferCancelled,
    pendingTransfer.amount,
    pendingTransfer.fromAccountId,
    pendingTransfer.toAccountId,
    pendingTransfer.transactionId,
    pushFlash,
  ]);

  return (
    <CancelPendingTransaction
      isCancellable={pendingTransfer.cancellable}
      onCancelTransaction={cancelTransfer}
      isLoading={isLoading}
      hasError={hasError}
      cancelMessage={intl.formatMessage({
        id: "pending-internal-transfer.cancel",
      })}
      errorMessage={intl.formatMessage({
        id: "pending-internal-transfer.cancelError",
      })}
      notCancellableMessage={intl.formatMessage({
        id: "pending-internal-transfer.notCancellable",
      })}
      confirmCancelMessage={intl.formatMessage({
        id: "pending-internal-transfer.confirmCancel",
      })}
      denyCancelMessage={intl.formatMessage({
        id: "pending-internal-transfer.denyCancel",
      })}
    />
  );
};
