import * as React from "react";
import { InvestmentAccount } from "../../../data/dataAccounts";
import { PeriodicWithdrawal } from "../../../data/dataWithdrawals";
import { TranslatedText } from "../../../components/TranslatedText";
import { PendingPeriodicWithdrawal } from "./PendingPeriodicWithdrawal";
import "./PendingWithdrawals.scss";
import { KfPendingWithdrawals } from "../KfPendingWithdrawals";
import { PendingKfWithdrawalResponse } from "../../../data/dataDanica";
import { Typography } from "@lysaab/ui-2";

interface Props {
  pendingPeriodicWithdrawals: PeriodicWithdrawal[];
  accounts: InvestmentAccount[];
  onPeriodicWithdrawalRemoved: (periodicWithdrawa: PeriodicWithdrawal) => void;
  kfPendingWithdrawals: PendingKfWithdrawalResponse[];
}

export const PendingWithdrawals: React.FunctionComponent<Props> = ({
  pendingPeriodicWithdrawals,
  accounts,
  onPeriodicWithdrawalRemoved,
  kfPendingWithdrawals,
}) => {
  if (
    pendingPeriodicWithdrawals.length === 0 &&
    kfPendingWithdrawals.length === 0
  ) {
    return <div className="withdrawal-page-pending" />;
  }

  return (
    <div className="withdrawal-page-pending">
      <KfPendingWithdrawals
        accounts={accounts}
        kfPendingWithdrawals={kfPendingWithdrawals}
      />

      {pendingPeriodicWithdrawals.length > 0 && (
        <div className="withdrawal-page-pending-periodic">
          <Typography type="h4">
            <TranslatedText id="withdrawals.pending.periodic.header" />
          </Typography>
          {pendingPeriodicWithdrawals.map((pendingPeriodicWithdrawal) => (
            <PendingPeriodicWithdrawal
              pendingPeriodicWithdrawal={pendingPeriodicWithdrawal}
              onPeriodicWithdrawalRemoved={onPeriodicWithdrawalRemoved}
              key={pendingPeriodicWithdrawal.updated}
              account={accounts.find(
                (account) =>
                  account.accountId === pendingPeriodicWithdrawal.accountId
              )}
            />
          ))}
        </div>
      )}
    </div>
  );
};
