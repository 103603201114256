import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { AccountName } from "../../../components/accountName/AccountName";
import { Amount } from "../../../components/amount/Amount";
import { Change } from "../../../components/amount/Change";
import { TranslatedText } from "../../../components/TranslatedText";
import { InvestmentAccount } from "../../../data/dataAccounts";
import { dataBanks } from "../../../data/dataBanks";
import { dataPerformance } from "../../../data/dataPerformance";
import {
  PeriodicWithdrawal,
  PERIODIC_TYPE,
} from "../../../data/dataWithdrawals";

import "./PendingPeriodicWithdrawal.scss";
import { CancelPeriodicWithdrawal } from "./components/periodicWithdrawal/CancelPeriodicWithdrawal";

interface Props {
  pendingPeriodicWithdrawal: PeriodicWithdrawal;
  account?: InvestmentAccount;
  onPeriodicWithdrawalRemoved: (periodicWithdrawa: PeriodicWithdrawal) => void;
}

const messages = defineMessages({
  [PERIODIC_TYPE.FIXED_AMOUNT]: {
    id: "withdrawals.periodic.fixed",
  },
  [PERIODIC_TYPE.PERCENTAGE]: {
    id: "withdrawals.periodic.percentage",
  },
  [PERIODIC_TYPE.PROFIT]: {
    id: "withdrawals.periodic.profit",
  },
  [PERIODIC_TYPE.TARGET_DATE]: {
    id: "withdrawals.periodic.target",
  },
});

export const PendingPeriodicWithdrawal: React.FunctionComponent<Props> = ({
  pendingPeriodicWithdrawal,
  account,
  onPeriodicWithdrawalRemoved,
}) => {
  const intl = useIntl();

  if (!account) {
    return null;
  }

  const bank = dataBanks.getBank(pendingPeriodicWithdrawal.bank);
  let elem = null;

  if (pendingPeriodicWithdrawal.type === PERIODIC_TYPE.FIXED_AMOUNT) {
    elem = (
      <li>
        <div className="pending-withdrawal-label">
          <TranslatedText id="withdrawals.periodic.amount" />
        </div>
        <div className="pending-withdrawal-bank-and-number">
          <Amount amount={pendingPeriodicWithdrawal.amount} />
        </div>
      </li>
    );
  } else if (pendingPeriodicWithdrawal.type === PERIODIC_TYPE.PERCENTAGE) {
    elem = (
      <li>
        <div className="pending-withdrawal-label">
          <TranslatedText id="withdrawals.periodic.percentage" />
        </div>
        <div className="pending-withdrawal-bank-and-number">
          <Change change={pendingPeriodicWithdrawal.amount} digits={1} />
        </div>
      </li>
    );
  } else if (pendingPeriodicWithdrawal.type === PERIODIC_TYPE.TARGET_DATE) {
    elem = (
      <li>
        <div className="pending-withdrawal-label">
          <TranslatedText id="withdrawals.periodic.endDate" />
        </div>
        <div className="pending-withdrawal-bank-and-number">
          {pendingPeriodicWithdrawal.endDate
            ? dataPerformance.toStringDate(
                dataPerformance.toJsDate(pendingPeriodicWithdrawal.endDate)
              )
            : "-"}
        </div>
      </li>
    );
  }

  return (
    <div className="pending-withdrawal pending-withdrawal-periodic">
      <ul>
        <li>
          <div className="pending-withdrawal-label">
            <TranslatedText id="withdrawals.periodic.from" />:
          </div>
          <AccountName account={account} showAmount={true} showRisk={true} />
        </li>
        <li>
          <div className="pending-withdrawal-label">
            <TranslatedText id="withdrawals.periodic.to" />:
          </div>
          <div className="pending-withdrawal-bank-and-number">
            {pendingPeriodicWithdrawal.externalBankAccount} -{" "}
            <strong>{bank.short}</strong>
          </div>
        </li>

        <li>
          <div className="pending-withdrawal-label">
            <TranslatedText id="withdrawals.periodic.plan" />
          </div>
          <div className="pending-withdrawal-bank-and-number">
            {intl.formatMessage(messages[pendingPeriodicWithdrawal.type])}
          </div>
        </li>

        <li>
          <div className="pending-withdrawal-label">
            <TranslatedText id="withdrawals.periodic.next" />
          </div>
          <div className="pending-withdrawal-bank-and-number">
            {pendingPeriodicWithdrawal.nextDeduction}
          </div>
        </li>

        {elem}
      </ul>

      <CancelPeriodicWithdrawal
        periodicWithdrawal={pendingPeriodicWithdrawal}
        onPeriodicWithdrawalCancelled={onPeriodicWithdrawalRemoved}
      />
    </div>
  );
};
