import type { FunctionComponent } from "react";
import { useCallback, useState } from "react";
import { EventTracker } from "../../../components/eventTracker/EventTracker";
import { TrackerEvent } from "../../../data/dataCustomerTracking";
import { SNACKBAR_TYPES, useFlash } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { dataWithdrawals } from "../../../data/dataWithdrawals";
import { DateTime } from "luxon";
import { PendingWithdrawal } from "./PendingWithdrawals";
import { CancelPendingTransaction } from "../../../components/pendingTransactions/CancelPendingTransaction";

const isCancellable = (pendingWithdrawal: PendingWithdrawal) => {
  return Boolean(
    pendingWithdrawal.pendingWithdrawal.cancellableUntil &&
      DateTime.fromISO(
        pendingWithdrawal.pendingWithdrawal.cancellableUntil
      ).diffNow("milliseconds").milliseconds > 0
  );
};

interface Props {
  pendingWithdrawal: PendingWithdrawal;
  onWithdrawalCancelled: () => void;
}

export const CancelPendingWithdrawal: FunctionComponent<Props> = ({
  pendingWithdrawal,
  onWithdrawalCancelled,
}) => {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const pushFlash = useFlash();

  const cancelWithdrawal = useCallback(() => {
    setHasError(false);
    setIsLoading(true);

    if ("investmentAccount" in pendingWithdrawal) {
      dataWithdrawals
        .deleteWithdrawal(pendingWithdrawal.investmentAccount.accountId)
        .then(() => {
          EventTracker.track({
            event: TrackerEvent.DELETE_WITHDRAWAL,
            message: `Cancelled withdrawal for account ${pendingWithdrawal.investmentAccount.accountId}`,
          });
          pushFlash({
            text: intl.formatMessage({
              id: "withdrawals.pending.cancelSuccess",
            }),
            type: SNACKBAR_TYPES.SUCCESS,
          });
          onWithdrawalCancelled();
          // We deliberately don't set isLoading to false here, as the component will flicker
          // with the "confirm"-part of <CancelPendingTransaction> component before closing.
        })
        .catch(() => {
          setHasError(true);
        });
    }

    if ("savingsAccount" in pendingWithdrawal) {
      dataWithdrawals
        .deletePendingSavingsAccountWithdrawals(
          pendingWithdrawal.savingsAccount.accountId
        )
        .then(() => {
          pushFlash({
            text: intl.formatMessage({
              id: "pending-internal-transfer.success",
            }),
            type: SNACKBAR_TYPES.SUCCESS,
          });
          onWithdrawalCancelled();
          // We deliberately don't set isLoading to false here, as the component will flicker
          // with the "confirm"-part of <CancelPendingTransaction> component before closing.
        })
        .catch(() => {
          setHasError(true);
        });
    }
  }, [intl, onWithdrawalCancelled, pendingWithdrawal, pushFlash]);

  return (
    <CancelPendingTransaction
      isCancellable={isCancellable(pendingWithdrawal)}
      onCancelTransaction={cancelWithdrawal}
      isLoading={isLoading}
      hasError={hasError}
      cancelMessage={intl.formatMessage({
        id: "withdrawals.pending.cancel",
      })}
      errorMessage={intl.formatMessage({
        id: "withdrawals.pending.cancelError",
      })}
      notCancellableMessage={intl.formatMessage({
        id: "withdrawals.pending.notCancellable",
      })}
      confirmCancelMessage={intl.formatMessage({
        id: "withdrawals.pending.confirmCancel",
      })}
      denyCancelMessage={intl.formatMessage({
        id: "withdrawals.pending.denyCancel",
      })}
    />
  );
};
