import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import {
  PendingInternalTransfer,
  dataTransfer,
} from "../../../data/dataTransfer";
import { FormattedDate, FormattedTime, useIntl } from "react-intl";
import { useCurrency } from "../../../context/LocalizationContext";
import { useAccounts } from "../../../hooks/useAccounts";
import { Spinner, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../components/TranslatedText";
import { CardButton } from "./CardButton";
import { CompoundAccount } from "../../../data/dataAccounts";
import { Modal } from "../../../components/modal/Modal";
import { CancelPendingInternalTransfer } from "./CancelPendingInternalTransfer";

export interface PendingTransfer extends PendingInternalTransfer {
  fromAccount: CompoundAccount;
  toAccount: CompoundAccount;
}

export const PendingInternalTransfers: React.FC = () => {
  const intl = useIntl();
  const currency = useCurrency();
  const { accounts } = useAccounts();
  const [pendingTransfers, setPendingTransfers] = useState<PendingTransfer[]>();
  const [pendingTransfer, setPendingTransfer] = useState<PendingTransfer>();
  const [showModal, setShowModal] = useState(false);

  const load = useCallback(() => {
    if (!accounts) {
      return;
    }
    const filteredAccounts = [
      ...accounts.investmentAccounts,
      ...accounts.savingsAccounts,
    ];
    dataTransfer.getPendingInternalTransfers().then((data) => {
      const mergedPendingInternalTransfers = data
        .filter((pendingInternalTransfer) => {
          const fromAccountExists = filteredAccounts.some(
            (account) =>
              account.accountId === pendingInternalTransfer.fromAccountId
          );
          const toAccountExists = filteredAccounts.some(
            (account) =>
              account.accountId === pendingInternalTransfer.toAccountId
          );
          return fromAccountExists && toAccountExists;
        })
        .map((validTransfer) => {
          const fromAccount = filteredAccounts.find(
            (account) => account.accountId === validTransfer.fromAccountId
          )!;
          const toAccount = filteredAccounts.find(
            (account) => account.accountId === validTransfer.toAccountId
          )!;
          return {
            ...validTransfer,
            fromAccount,
            toAccount,
          };
        });
      setPendingTransfers(mergedPendingInternalTransfers);
    });
  }, [accounts]);

  useEffect(() => {
    load();
  }, [load]);

  if (!pendingTransfers?.length) {
    return null;
  }

  return (
    <div className="overview-pending">
      <div>
        <Typography type="h2">
          <TranslatedText id="pending-internal-transfer.header" />
        </Typography>
        <div className="list">
          {pendingTransfers.map((pendingInternalTransfer) => {
            return (
              <CardButton
                key={pendingInternalTransfer.transactionId}
                onClick={() => {
                  setPendingTransfer(pendingInternalTransfer);
                  setShowModal(true);
                }}
                text={pendingInternalTransfer.toAccount.name}
                secondaryText={
                  typeof pendingInternalTransfer.amount !== "undefined"
                    ? intl.formatNumber(pendingInternalTransfer.amount, {
                        currency,
                        style: "currency",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : undefined
                }
              />
            );
          })}
        </div>
      </div>

      <Modal
        header={intl.formatMessage({
          id: "pending-internal-transfer.modal.header",
        })}
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        closeOnOverlayClick
        className="overview-pending-modal"
      >
        <div className="modal-content">
          {typeof pendingTransfer === "undefined" && <Spinner />}
          {typeof pendingTransfer !== "undefined" && (
            <>
              <div className="row">
                <Typography type="label">
                  <TranslatedText id="pending-internal-transfer.amount" />
                </Typography>
                <Typography className="text" component="span">
                  {typeof pendingTransfer.amount === "number" ? (
                    intl.formatNumber(pendingTransfer.amount, {
                      currency,
                      style: "currency",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  ) : (
                    <TranslatedText id="pending-internal-transfer.amount.drain-text" />
                  )}
                </Typography>
              </div>

              <div className="row">
                <Typography type="label">
                  <TranslatedText id="pending-internal-transfer.fromAccount" />
                </Typography>
                <Typography className="text" component="span">
                  {pendingTransfer.fromAccount.name}
                </Typography>
              </div>

              <div className="row">
                <Typography type="label">
                  <TranslatedText id="pending-internal-transfer.toAccount" />
                </Typography>
                <Typography className="text" component="span">
                  {pendingTransfer.toAccount.name}
                </Typography>
              </div>

              <div className="row">
                <Typography type="label">
                  <TranslatedText id="pending-internal-transfer.created" />
                </Typography>
                <Typography className="text" component="span">
                  <FormattedDate value={pendingTransfer.created} />
                  {", "}
                  <FormattedTime value={pendingTransfer.created} />
                </Typography>
              </div>

              <CancelPendingInternalTransfer
                pendingTransfer={pendingTransfer}
                onTransferCancelled={() => {
                  load();
                  setShowModal(false);
                }}
              />
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
