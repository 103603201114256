import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { PaymentStatus, isPaymentStatus } from "../../../data/dataAutogiro";
import { CompoundAccountId } from "../../../data/dataAccounts";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../../components/TranslatedText";
import { Spinner, Typography } from "@lysaab/ui-2";
import { usePendingDeposits } from "../../../hooks/usePendingDeposits";
import { useCurrency } from "../../../context/LocalizationContext";
import { useAccounts } from "../../../hooks/useAccounts";
import { Modal } from "../../../components/modal/Modal";
import { CardButton } from "./CardButton";
import cx from "classnames";
import { useAccountName } from "../../../hooks/useAccountName";
import { CancelPendingDeposit } from "./CancelPendingDeposit";

export interface PendingDeposit {
  status: PaymentStatus | PendingDepositStatus;
  abort?: () => Promise<boolean>;
  amount: number;
  accountId: CompoundAccountId;
  dateAdded?: string;
  transactionId?: string;
  from?: string;
}
export enum PendingDepositStatus {
  ISK_TRANSFER = "ISK_TRANSFER",
}

export const depositStatusMessages = defineMessages<PaymentStatus>({
  [PaymentStatus.WAITING_CONSENT]: {
    id: "pending-deposit.status.awaiting-consent",
  },
  [PaymentStatus.WAITNG_DISPATCH]: {
    id: "pending-deposit.status.awaiting-dispatch",
  },
  [PaymentStatus.SENT]: {
    id: "pending-deposit.status.sent",
  },
});

// FIXME: Handle isk transfers
export const PendingDeposits: React.FC = () => {
  const intl = useIntl();
  const accounts = useAccounts();
  const currency = useCurrency();
  const loadPendingDeposits = usePendingDeposits();
  const [showModal, setShowModal] = useState(false);
  const [pendingDeposit, setPendingDeposit] = useState<PendingDeposit>();
  const [pendingDeposits, setPendingDeposits] = useState<PendingDeposit[]>([]);

  const accountName = useAccountName(pendingDeposit?.accountId);
  const getPendingDeposits = useCallback(() => {
    if (typeof loadPendingDeposits === "undefined") {
      return;
    }
    loadPendingDeposits().then((pendingDeposits) => {
      const filteredDepositsNoIskTransfers = pendingDeposits.filter(
        (pendingDeposit) =>
          pendingDeposit.status !== PendingDepositStatus.ISK_TRANSFER
      );
      setPendingDeposits(filteredDepositsNoIskTransfers);
      // setPendingDeposits(DUMMY_DATA);
    });
  }, [loadPendingDeposits]);

  useEffect(() => {
    getPendingDeposits();
  }, [getPendingDeposits]);

  if (!pendingDeposits.length) {
    return null;
  }

  return (
    <div className="overview-pending">
      <div>
        <Typography type="h2">
          <TranslatedText id="pending-deposit.header" />
        </Typography>
        <div className="list">
          {pendingDeposits.map((deposit, index) => {
            const account =
              accounts.accounts?.investmentAccounts.find(
                (account) => account.accountId === deposit.accountId
              ) ??
              accounts.accounts?.savingsAccounts.find(
                (account) => account.accountId === deposit.accountId
              );

            if (!account) {
              return null;
            }

            return (
              <CardButton
                key={deposit.transactionId}
                onClick={() => {
                  setPendingDeposit(deposit);
                  setShowModal(true);
                }}
                text={account.name}
                secondaryText={intl.formatNumber(deposit.amount, {
                  currency,
                  signDisplay: "exceptZero",
                  style: "currency",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              />
            );
          })}
        </div>
      </div>

      <Modal
        header={intl.formatMessage({ id: "pending-deposit.modal.header" })}
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        closeOnOverlayClick
        className="overview-pending-modal"
      >
        <div className="modal-content">
          {typeof pendingDeposit === "undefined" && <Spinner />}
          {typeof pendingDeposit !== "undefined" && (
            <>
              {typeof pendingDeposit?.amount === "number" && (
                <div className="row">
                  <Typography type="label">
                    <TranslatedText id="pending-deposit.amount-header" />
                  </Typography>
                  <Typography className="text" component="span">
                    {intl.formatNumber(pendingDeposit?.amount, {
                      currency,
                      signDisplay: "exceptZero",
                      style: "currency",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </div>
              )}

              <div className="row">
                <Typography type="label">
                  <TranslatedText id="pending-deposit.account-header" />
                </Typography>
                <Typography className="text" component="span">
                  {accountName}
                </Typography>
              </div>

              <div
                className={cx("row", {
                  rowNoMargin:
                    (typeof pendingDeposit?.status === "undefined" ||
                      !isPaymentStatus(pendingDeposit.status)) &&
                    typeof pendingDeposit?.abort === "undefined",
                })}
              >
                <Typography type="label">
                  <TranslatedText id="pending-deposit.date-added-header" />
                </Typography>
                <Typography className="text" component="span">
                  {intl.formatDate(pendingDeposit?.dateAdded)}
                </Typography>
              </div>

              {typeof pendingDeposit?.status !== "undefined" &&
                isPaymentStatus(pendingDeposit.status) && (
                  <div
                    className={cx("row", {
                      rowNoMargin: typeof pendingDeposit?.abort === "undefined",
                    })}
                  >
                    <Typography type="label">
                      <TranslatedText id="pending-deposit.status-header" />
                    </Typography>
                    <Typography className="text" component="span">
                      {intl.formatMessage(
                        depositStatusMessages[pendingDeposit.status]
                      )}
                    </Typography>
                  </div>
                )}

              {typeof pendingDeposit?.abort !== "undefined" &&
                pendingDeposit.status !== PaymentStatus.SENT && (
                  <CancelPendingDeposit
                    pendingDeposit={pendingDeposit}
                    onDepositCancelled={() => {
                      setPendingDeposit(undefined);
                      setShowModal(false);
                      getPendingDeposits();
                    }}
                  />
                )}
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
