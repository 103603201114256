import { useCallback, useEffect, useState } from "react";
import { useAccounts } from "../../../hooks/useAccounts";
import {
  ExternalPendingSavingsWithdrawalResponse,
  Withdrawal,
  dataWithdrawals,
} from "../../../data/dataWithdrawals";
import { DateTime } from "luxon";
import {
  AccountType,
  InvestmentAccount,
  SavingsAccount,
} from "../../../data/dataAccounts";
import { Spinner, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../components/TranslatedText";
import { CardButton } from "./CardButton";
import { FormattedDate, FormattedTime, useIntl } from "react-intl";
import { useCurrency } from "../../../context/LocalizationContext";
import { Modal } from "../../../components/modal/Modal";
import { dataBanks } from "../../../data/dataBanks";
import { CancelPendingWithdrawal } from "./CancelPendingWithdrawal";

interface InvestmentWithdrawal {
  investmentAccount: InvestmentAccount;
  pendingWithdrawal: Withdrawal;
}

interface SavingsWithdrawal {
  savingsAccount: SavingsAccount;
  pendingWithdrawal: ExternalPendingSavingsWithdrawalResponse;
}

export type PendingWithdrawal = InvestmentWithdrawal | SavingsWithdrawal;

// FIXME: Add KF withdrawals
export const PendingWithdrawals = () => {
  const intl = useIntl();
  const currency = useCurrency();
  const { accounts } = useAccounts();
  const [pendingWithdrawals, setPendingWithdrawals] = useState<Withdrawal[]>(
    []
  );
  const [
    pendingSavingsAccountWithdrawals,
    setPendingSavingsAccountWithdrawals,
  ] = useState<ExternalPendingSavingsWithdrawalResponse[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [pendingWithdrawal, setPendingWithdrawal] =
    useState<PendingWithdrawal>();

  const loadPendingAccountWithdrawals = useCallback(() => {
    Promise.all([
      dataWithdrawals.getPendingWithdrawals(),
      dataWithdrawals.getPendingSavingsAccountWithdrawals(),
    ]).then(([pendingWithdrawals, pendingSavingsWithdrawals]) => {
      setPendingWithdrawals(pendingWithdrawals);
      setPendingSavingsAccountWithdrawals(pendingSavingsWithdrawals);
    });
  }, []);

  useEffect(() => {
    loadPendingAccountWithdrawals();
  }, [loadPendingAccountWithdrawals]);

  if (
    typeof accounts === "undefined" ||
    (pendingWithdrawals.length === 0 &&
      pendingSavingsAccountWithdrawals.length === 0)
  ) {
    return null;
  }

  // Yes, I know, KF logic in a generic component. Both
  // /withdrawal/pending and /danica/withdrawal/pending might
  // contain the same withdrawal. Unfortunately we'll need to
  // display the duplicates from the /danica/withdrawal/pending
  // - it has the information we need.
  const filteredPendingWithdrawals = excludePendingKFWithdrawals(
    pendingWithdrawals,
    accounts.investmentAccounts
  );

  return (
    <div className="overview-pending">
      <div>
        <Typography type="h2">
          <TranslatedText id="pending-withdrawals.header" />
        </Typography>
        <div className="list">
          {[
            ...filteredPendingWithdrawals.reduce<InvestmentWithdrawal[]>(
              (pendingWithdrawals, pendingWithdrawal) => {
                const account = accounts.investmentAccounts.find(
                  (account) => account.accountId === pendingWithdrawal.accountId
                );

                if (typeof account !== "undefined") {
                  pendingWithdrawals.push({
                    investmentAccount: account,
                    pendingWithdrawal,
                  });
                }
                return pendingWithdrawals;
              },
              []
            ),
            ...pendingSavingsAccountWithdrawals.reduce<SavingsWithdrawal[]>(
              (pendingWithdrawals, pendingWithdrawal) => {
                const savingsAccount = accounts.savingsAccounts.find(
                  (account) => account.accountId === pendingWithdrawal.accountId
                );

                if (typeof savingsAccount !== "undefined") {
                  pendingWithdrawals.push({
                    savingsAccount,
                    pendingWithdrawal,
                  });
                }

                return pendingWithdrawals;
              },
              []
            ),
          ]
            .sort((withdrawalA, withdrawalB) =>
              DateTime.fromISO(withdrawalA.pendingWithdrawal.requested)
                .diff(DateTime.fromISO(withdrawalB.pendingWithdrawal.requested))
                .toMillis()
            )
            .map((withdrawal) => {
              if ("investmentAccount" in withdrawal) {
                return (
                  <CardButton
                    key={
                      withdrawal.pendingWithdrawal.accountId +
                      withdrawal.pendingWithdrawal.externalBankAccount +
                      withdrawal.pendingWithdrawal.requested
                    }
                    onClick={() => {
                      setPendingWithdrawal(withdrawal);
                      setShowModal(true);
                    }}
                    text={withdrawal.investmentAccount.name}
                    secondaryText={intl.formatNumber(
                      withdrawal.pendingWithdrawal.drain
                        ? -withdrawal.investmentAccount.worth
                        : -withdrawal.pendingWithdrawal.amount,
                      {
                        currency,
                        signDisplay: "exceptZero",
                        style: "currency",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  />
                );
              }

              if ("savingsAccount" in withdrawal) {
                return (
                  <CardButton
                    key={
                      withdrawal.pendingWithdrawal.accountId +
                      withdrawal.pendingWithdrawal.externalBankAccount +
                      withdrawal.pendingWithdrawal.requested
                    }
                    onClick={() => {
                      setPendingWithdrawal(withdrawal);
                      setShowModal(true);
                    }}
                    text={withdrawal.savingsAccount.name}
                    secondaryText={intl.formatNumber(
                      -withdrawal.pendingWithdrawal.amount,
                      {
                        currency,
                        signDisplay: "exceptZero",
                        style: "currency",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  />
                );
              }

              return null;
            })}
        </div>
      </div>

      <Modal
        header={intl.formatMessage({ id: "pending-withdrawals.modal.header" })}
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        closeOnOverlayClick
        className="overview-pending-modal"
      >
        <div className="modal-content">
          {typeof pendingWithdrawal === "undefined" && <Spinner />}
          {typeof pendingWithdrawal !== "undefined" && (
            <>
              <div className="row">
                <Typography type="label">
                  <TranslatedText id="pending-withdrawals.amount" />
                </Typography>
                <Typography className="text" component="span">
                  {"investmentAccount" in pendingWithdrawal &&
                  pendingWithdrawal.pendingWithdrawal.drain
                    ? intl.formatNumber(
                        -pendingWithdrawal.investmentAccount.worth,
                        {
                          currency,
                          signDisplay: "exceptZero",
                          style: "currency",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    : intl.formatNumber(
                        -pendingWithdrawal.pendingWithdrawal.amount,
                        {
                          currency,
                          signDisplay: "exceptZero",
                          style: "currency",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                </Typography>
              </div>

              <div className="row">
                <Typography type="label">
                  <TranslatedText id="pending-withdrawals.fromAccount" />
                </Typography>
                <Typography className="text" component="span">
                  {"investmentAccount" in pendingWithdrawal
                    ? pendingWithdrawal.investmentAccount.name
                    : pendingWithdrawal.savingsAccount.name}
                </Typography>
              </div>

              <div className="row">
                <Typography type="label">
                  <TranslatedText id="pending-withdrawals.toAccount" />
                </Typography>
                <Typography className="text" component="span">
                  {pendingWithdrawal.pendingWithdrawal.externalBankAccount}
                  {(() => {
                    const bank = dataBanks.getBank(
                      pendingWithdrawal.pendingWithdrawal.bank
                    );
                    return bank.short !== "Unknown" ? ", " + bank.long : null;
                  })()}
                </Typography>
              </div>

              <div className="row">
                <Typography type="label">
                  <TranslatedText id="pending-withdrawals.created" />
                </Typography>
                <Typography className="text" component="span">
                  <FormattedDate
                    value={pendingWithdrawal.pendingWithdrawal.requested}
                  />
                  {", "}
                  <FormattedTime
                    value={pendingWithdrawal.pendingWithdrawal.requested}
                  />
                </Typography>
              </div>

              <CancelPendingWithdrawal
                pendingWithdrawal={pendingWithdrawal}
                onWithdrawalCancelled={() => {
                  setPendingWithdrawal(undefined);
                  setShowModal(false);
                  loadPendingAccountWithdrawals();
                }}
              />
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

function excludePendingKFWithdrawals(
  pendingWithdrawals: Withdrawal[],
  accounts: InvestmentAccount[]
) {
  return pendingWithdrawals.filter((pendingItem) => {
    const account = accounts.find(
      (accountItem) => accountItem.accountId === pendingItem.accountId
    );
    return account?.type !== AccountType.DANICA_KF;
  });
}
