import { useCallback, useEffect, useState } from "react";
import {
  AccountType,
  InvestmentAccount,
} from "../../../../../data/dataAccounts";
import {
  dataWithdrawals,
  ExternalPendingSavingsWithdrawalResponse,
  Withdrawal,
} from "../../../../../data/dataWithdrawals";
import { useAccounts } from "../../../../../hooks/useAccounts";
import { PendingWithdrawal } from "./PendingWithdrawal";
import { DateTime } from "luxon";

export const PendingAccountWithdrawals = () => {
  const { accounts } = useAccounts();
  const [pendingWithdrawals, setPendingWithdrawals] = useState<Withdrawal[]>(
    []
  );
  const [
    pendingSavingsAccountWithdrawals,
    setPendingSavingsAccountWithdrawals,
  ] = useState<ExternalPendingSavingsWithdrawalResponse[]>([]);

  const loadPendingAccountWithdrawals = useCallback(() => {
    Promise.all([
      dataWithdrawals.getPendingWithdrawals(),
      dataWithdrawals.getPendingSavingsAccountWithdrawals(),
    ]).then(([pendingWithdrawals, pendingSavingsWithdrawals]) => {
      setPendingWithdrawals(pendingWithdrawals);
      setPendingSavingsAccountWithdrawals(pendingSavingsWithdrawals);
    });
  }, []);

  useEffect(() => {
    loadPendingAccountWithdrawals();
  }, [loadPendingAccountWithdrawals]);

  if (typeof accounts === "undefined") {
    return null;
  }

  // Yes, I know, KF logic in a generic component. Both
  // /withdrawal/pending and /danica/withdrawal/pending might
  // contain the same withdrawal. Unfortunately we'll need to
  // display the duplicates from the /danica/withdrawal/pending
  // - it has the information we need.
  const filteredPendingWithdrawals = excludePendingKFWithdrawals(
    pendingWithdrawals,
    accounts.investmentAccounts
  );

  return (
    <div className="withdrawal-page-pending-single">
      <div>
        {[...filteredPendingWithdrawals, ...pendingSavingsAccountWithdrawals]
          .sort((withdrawalA, withdrawalB) =>
            DateTime.fromISO(withdrawalA.requested)
              .diff(DateTime.fromISO(withdrawalB.requested))
              .toMillis()
          )
          .map((pendingWithdrawal) => {
            const account = accounts.investmentAccounts.find(
              (account) => account.accountId === pendingWithdrawal.accountId
            );
            if (typeof account !== "undefined") {
              return (
                <PendingWithdrawal
                  pendingWithdrawal={pendingWithdrawal}
                  key={
                    pendingWithdrawal.accountId +
                    pendingWithdrawal.externalBankAccount
                  }
                  account={account}
                  loadPendingAccountWithdrawals={loadPendingAccountWithdrawals}
                />
              );
            } else {
              const savingsAccount = accounts.savingsAccounts.find(
                (account) => account.accountId === pendingWithdrawal.accountId
              );
              if (typeof savingsAccount === "undefined") {
                return null;
              }

              return (
                <PendingWithdrawal
                  pendingWithdrawal={pendingWithdrawal}
                  key={
                    pendingWithdrawal.accountId +
                    pendingWithdrawal.externalBankAccount
                  }
                  account={savingsAccount}
                  loadPendingAccountWithdrawals={loadPendingAccountWithdrawals}
                />
              );
            }
          })}
      </div>
    </div>
  );
};

function excludePendingKFWithdrawals(
  pendingWithdrawals: Withdrawal[],
  accounts: InvestmentAccount[]
) {
  return pendingWithdrawals.filter((pendingItem) => {
    const account = accounts.find(
      (accountItem) => accountItem.accountId === pendingItem.accountId
    );
    return account?.type !== AccountType.DANICA_KF;
  });
}
