import type {
  Dispatch,
  SetStateAction,
  ReactNode,
  ComponentProps,
  FunctionComponent,
  ReactElement,
} from "react";
import { useCallback, useState } from "react";
import cx from "classnames";
import {
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { HideIfReadOnly } from "../hideIfReadOnly/HideIfReadOnly";

import "./CancelPendingTransaction.scss";

type ButtonProps = ComponentProps<typeof Button>;
type ContainerProps = ComponentProps<"div">;
interface IdleCancelPendingTransactionProps {
  isCancellable: boolean;
  renderIdleButton?: (props: ButtonProps) => ReactNode;
  renderContainer?: (props: ContainerProps) => ReactElement;
  onStart: () => void;
  cancelMessage: string;
  notCancellableMessage?: string;
  hasError: boolean;
  errorMessage?: string;
}

const IdleCancelPendingTransaction: FunctionComponent<
  IdleCancelPendingTransactionProps
> = ({
  isCancellable,
  renderIdleButton,
  renderContainer,
  onStart,
  hasError,
  cancelMessage,
  errorMessage,
  notCancellableMessage,
}) => {
  const buttonProps: ButtonProps = {
    variant: "negative",
    onClick: onStart,
    label: cancelMessage,
  };

  let content;
  if (isCancellable) {
    content = (
      <>
        {renderIdleButton ? (
          renderIdleButton(buttonProps)
        ) : (
          <Button {...buttonProps} />
        )}
        {hasError && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            {errorMessage}
          </Snackbar>
        )}
      </>
    );
  } else {
    content = (
      <Typography type="body" component="i">
        {notCancellableMessage}
      </Typography>
    );
  }

  const containerProps: ContainerProps = {
    className: cx(
      "cancel-pending-transaction",
      hasError && "cancel-pending-transaction--error"
    ),
    children: content,
  };

  if (renderContainer) {
    return renderContainer(containerProps);
  }
  return <div {...containerProps} />;
};

interface CancelPendingTransactionConfirmationProps {
  onConfirm: () => void;
  onDeny: () => void;
  isLoading: boolean;
  confirmCancelMessage: string;
  cancelMessage: string;
  denyCancelMessage: string;
}

const CancelPendingTransactionConfirmation: FunctionComponent<
  CancelPendingTransactionConfirmationProps
> = ({
  onConfirm,
  onDeny,
  isLoading,
  confirmCancelMessage,
  cancelMessage,
  denyCancelMessage,
}) => {
  return (
    <div className="cancel-pending-transaction__confirm">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Typography type="label-large">{confirmCancelMessage}</Typography>
          <Button
            variant="negative"
            block
            onClick={onConfirm}
            label={cancelMessage}
          />
          <Button
            variant="secondary"
            block
            onClick={onDeny}
            label={denyCancelMessage}
          />
        </>
      )}
    </div>
  );
};

enum CancelPendingTransactionState {
  Idle,
  Confirm,
}

/**
 * When the hasError prop changes, it means a new error has occurred.
 * Supress the confirmation dialog if the error has just occurred.
 * If you choose to try and confirm again, unsupress the confirmation dialog.
 */
const useSupressConfirm = (
  hasError: boolean
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [supressConfirm, setSupressConfirm] = useState(hasError);
  const [prevHasError, setPrevHasError] = useState(hasError);
  if (prevHasError !== hasError) {
    // The error was just changed to true, which means
    // we supress the confirmation dialog until
    // the user tries to confirm again.
    if (prevHasError === false) {
      setSupressConfirm(true);
    } else {
      setSupressConfirm(false);
    }
    setPrevHasError(hasError);
  }
  return [supressConfirm, setSupressConfirm];
};

interface BaseProps {
  onCancelTransaction: () => void;
  renderIdleButton?: (props: ButtonProps) => ReactNode;
  renderContainer?: (props: ContainerProps) => ReactElement;
  isLoading: boolean;
  cancelMessage: string;
  confirmCancelMessage: string;
  denyCancelMessage: string;
}

interface NotCancellableProps {
  isCancellable: true;
  notCancellableMessage?: string;
}

interface IsCancellableProps {
  isCancellable: false;
  notCancellableMessage: string;
}

type CancellableProps = IsCancellableProps | NotCancellableProps;

interface WithErrorProps {
  hasError: true;
  errorMessage: string;
}

interface WithoutErrorProps {
  hasError: false;
  errorMessage?: string;
}

type ErrorProps = WithoutErrorProps | WithErrorProps;

type Props = BaseProps & CancellableProps & ErrorProps;

export const CancelPendingTransaction: FunctionComponent<Props> = ({
  isCancellable,
  renderIdleButton,
  renderContainer,
  onCancelTransaction,
  isLoading = false,
  hasError = false,
  cancelMessage,
  errorMessage,
  notCancellableMessage,
  confirmCancelMessage,
  denyCancelMessage,
}) => {
  const [state, setState] = useState<CancelPendingTransactionState>(
    CancelPendingTransactionState.Idle
  );
  const [supressConfirm, setSupressConfirm] = useSupressConfirm(hasError);

  const onStart = useCallback(() => {
    setSupressConfirm(false);
    setState(CancelPendingTransactionState.Confirm);
  }, [setSupressConfirm]);
  const onDeny = useCallback(() => {
    setState(CancelPendingTransactionState.Idle);
  }, []);

  return (
    <HideIfReadOnly>
      <IdleCancelPendingTransaction
        isCancellable={isCancellable}
        renderIdleButton={renderIdleButton}
        renderContainer={renderContainer}
        onStart={onStart}
        hasError={hasError}
        cancelMessage={cancelMessage}
        errorMessage={errorMessage}
        notCancellableMessage={notCancellableMessage}
      />
      {state === CancelPendingTransactionState.Confirm && !supressConfirm && (
        <CancelPendingTransactionConfirmation
          onConfirm={onCancelTransaction}
          onDeny={onDeny}
          isLoading={isLoading}
          confirmCancelMessage={confirmCancelMessage}
          cancelMessage={cancelMessage}
          denyCancelMessage={denyCancelMessage}
        />
      )}
    </HideIfReadOnly>
  );
};
