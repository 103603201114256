import {
  ComponentProps,
  FunctionComponent,
  ReactNode,
  useCallback,
  useState,
} from "react";
import { Button, SNACKBAR_TYPES, useFlash } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import {
  MonthlyInternalTransfer,
  dataPeriodicInternalTransfer,
} from "../../data/dataPeriodicInternalTransfer";
import { CancelPendingTransaction } from "../../components/pendingTransactions/CancelPendingTransaction";

export const CancelMonthlyInternalTransfer: FunctionComponent<{
  monthlyTransfer: MonthlyInternalTransfer;
  onTransferCancelled: () => void;
  renderButton: (props: ComponentProps<typeof Button>) => ReactNode;
}> = ({ monthlyTransfer, onTransferCancelled, renderButton }) => {
  const [isLoading, setIsLoading] = useState(false);
  const pushFlash = useFlash();
  const intl = useIntl();

  const cancelTransfer = useCallback(() => {
    setIsLoading(true);

    dataPeriodicInternalTransfer
      .deleteMonthlyInternalTransfer(monthlyTransfer.id)
      .then(() => {
        pushFlash({
          text: intl.formatMessage({
            id: "accountPage.monthly-internal-transfers.entry.cancelSuccess",
          }),
          type: SNACKBAR_TYPES.SUCCESS,
        });
        onTransferCancelled();
        // We deliberately don't set isLoading to false here, as the component will flicker
        // with the "confirm"-part of <CancelPendingTransaction> component before closing.
      })
      .catch(() => {
        pushFlash({
          text: intl.formatMessage({
            id: "accountPage.monthly-internal-transfers.entry.cancelError",
          }),
          type: SNACKBAR_TYPES.ERROR,
        });
        setIsLoading(false);
      });
  }, [intl, monthlyTransfer.id, onTransferCancelled, pushFlash]);

  return (
    <CancelPendingTransaction
      isCancellable={true}
      hasError={false}
      renderIdleButton={renderButton}
      // Render children without any container at all.
      // Rendering with the container means we have to reset alot of specific
      // styling for how the CancelMonthlyInternalTransfer button is placed in the ui.
      renderContainer={({ children }) => <>{children}</>}
      onCancelTransaction={cancelTransfer}
      isLoading={isLoading}
      cancelMessage={intl.formatMessage({
        id: "accountPage.monthly-internal-transfers.entry.cancel",
      })}
      notCancellableMessage={intl.formatMessage({
        id: "accountPage.monthly-internal-transfers.entry.notCancellable",
      })}
      confirmCancelMessage={intl.formatMessage({
        id: "accountPage.monthly-internal-transfers.entry.confirmCancel",
      })}
      denyCancelMessage={intl.formatMessage({
        id: "accountPage.monthly-internal-transfers.entry.denyCancel",
      })}
    />
  );
};
